import React, { Component } from "react";
import { Radio, FormControlLabel, RadioGroup, CircularProgress, IconButton, Icon, Grid, MenuItem, withStyles, Fab, Button, DialogContent, Dialog, DialogActions, DialogTitle, Avatar, Card } from "@material-ui/core";

import moment from 'moment';
import 'moment-timezone';//timezone

import SystemLog from "./SystemLog"
import NoteLog from "./NoteLog";
import CallLogs from "./CallLogs";

import EmailTypeOne from "./EmailTypeOne";
import EmailTypeTwo from "./EmailTypeTwo";
import EmailTypeFour from "./EmailTypeFour";

import FilterLogoCommon from "../../CommonLogUI/FilterLogoCommon";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';

class ActivityDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateformat: 'MM/DD/YYYY',
      timeZone: 'America/New_York',
      limit: 5,
      totalPages: 0,
      currentPage: 1,
      activitylist: [],
      hasMore: false,
      isDataLoading: true,
      logid: 0,
      allLogs: [],
      value_type: "true",
      isOpenedEmailIds: [],
      pagesLoaded: [],
      memberid: 0
    };
  }
  componentDidMount() {
    this.updateGraphSettings();
  }

  componentWillReceiveProps(props) {
    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;
      this.setState({ dateformat: usersetting.dateformat, timeZone: usersetting.timezone })
    }

    if (props.reportReducer.activitydashboardreportlist.data && props.reportReducer.activitydashboardreportlist.data.data) {
      let activitylist = props.reportReducer.activitydashboardreportlist.data.data;
      let source = "";
      let logdata = [];
      let totalpage = activitylist.totalpage;
      let pined = activitylist.pined;
      let today = activitylist.today;
      let yesterday = activitylist.yesterday;
      let thisweek = activitylist.thisweek;
      let lastweek = activitylist.lastweek;
      let thismonth = activitylist.thismonth;
      let lastmonth = activitylist.lastmonth;
      let earlier = activitylist.earlier;

      logdata.push(this.createLogArray(source, pined, 'Pinned'));
      logdata.push(this.createLogArray(source, today, 'Today'));
      logdata.push(this.createLogArray(source, yesterday, 'Yesterday'));
      logdata.push(this.createLogArray(source, thisweek, 'This week'));
      logdata.push(this.createLogArray(source, lastweek, 'Last week'));
      logdata.push(this.createLogArray(source, thismonth, 'This month'));
      logdata.push(this.createLogArray(source, lastmonth, 'Last month'));
      logdata.push(this.createLogArray(source, earlier, 'Earlier'));

      if (this.state.currentPage === 1) {
        this.setState({
          allLogs: logdata,
          isDataLoading: false,
          hasMore: totalpage > this.state.currentPage ? true : false,
          totalPages: totalpage
        }, () => this.forceUpdate())
      } else {
        if (this.state.pagesLoaded.indexOf(this.state.currentPage) == -1) {
          this.AddNewData(logdata)
        }
        this.setState({
          hasMore: totalpage > this.state.currentPage ? true : false,
          // allLogs: [...this.state.allLogs, ...logdata],
          isDataLoading: false,
        }, () => this.forceUpdate())
      }
    } else {
      setTimeout(() => {
        this.setState({ isDataLoading: false, });
      }, 1500);
    }
    if (props.reportReducer.UserDashboardId) {
      if (this.state.memberid !== props.reportReducer.UserDashboardId) {
        {
          this.setState({
            memberid: props.reportReducer.UserDashboardId
          }, () => this.updateGraphSettings())
        }
      }
    }
  }

  fetchMoreData = () => {
    // e.preventDefault();
    if (this.state.currentPage >= this.state.totalPages) {
      this.setState({ hasMore: false });
      return;
    }
    this.getNextPageData(this.state.currentPage + 1)
  };

  getNextPageData = (page) => {
    this.setState({
      currentPage: page, isDataLoading: true
    }, () => {
      this.forceUpdate()
      var request = {
        limit: this.state.limit,
        pageno: page,
        ismyactivity: (this.state.value_type == "true"),
        memberid: this.state.memberid
      }
      this.props.getActivityReport(this.props.apolloClient.client, request)
    })
  }

  AddNewData = (logdata) => {
    let pagesLoaded = this.state.pagesLoaded;
    var okData = [...this.state.allLogs]
    for (var i = 0; i < logdata.length; i++) {
      if (logdata[i].key == okData[i].key) {
        okData[i].datas = [...okData[i].datas, ...logdata[i].datas]
      }
    }
    pagesLoaded.push(this.state.currentPage);
    this.setState({ pagesLoaded })
  }

  setSourceDetail = (data, template1) => {
    if (template1 == null)
      return false;

    let new_template = "";
    let old_template = "";
    old_template = template1.replace("{{source_type}}", data.sourcetype);


    if (data.__source__ && data.__source__.__leaddetail__) {
      new_template = old_template.replace("{{source}}", '<a class="text_link" href="/lead/lead/' + data.__source__.id + '" >' + data.__source__.__leaddetail__[0].fieldvalue.trim() + '</a>');
    }
    if (data.__source__ && data.__source__.__contactdetail__) {
      new_template = old_template.replace("{{source}}", '<a class="text_link" href="/contact/contact/' + data.__source__.id + '" >' + data.__source__.__contactdetail__[0].fieldvalue.trim() + '</a>');
    }
    if (data.__source__ && data.__source__.__companydetail__) {
      new_template = old_template.replace("{{source}}", '<a class="text_link" href="/company/company/' + data.__source__.id + '" >' + data.__source__.__companydetail__[0].fieldvalue.trim() + '</a>');
    }
    if (data.__source__ && data.__source__.__opportunitiesdetail__) {
      new_template = old_template.replace("{{source}}", '<a class="text_link" href="/opportunities/opportunities/' + data.__source__.id + '" >' + data.__source__.__opportunitiesdetail__[0].fieldvalue.trim() + '</a>');
    }
    if (data.__source__ && data.__source__.__projectdetail__) {
      new_template = old_template.replace("{{source}}", '<a class="text_link" href="/project/projects/' + data.__source__.id + '" >' + data.__source__.__projectdetail__[0].fieldvalue.trim() + '</a>');
    }
    if (data.__source__ && data.__source__.__taskdetail__) {
      new_template = old_template.replace("{{source}}", '<a class="text_link" href="/task/task-list/' + data.__source__.id + '" >' + data.__source__.__taskdetail__[0].fieldvalue.trim() + '</a>');
    }

    return new_template;
  }

  createLogArray = (source, partData, partKey) => {
    let logData = [];

    if (partData && partData.length > 0) {
      let logged_in_user_email = JSON.parse(localStorage.getItem("user_data_session")).email;
      partData.map((data) => {
        let pinned = data.ispined;
        if (data.email_type) {
          let log_user = JSON.parse(localStorage.getItem('user_data_session'));
          let color_class = (log_user.name) ? log_user.name.charAt(0) : "";
          if (data.email_type == 1) {
            let date = moment(data.details.email_date_time).format("X");
            logData.push(
              {
                logId: data.id,
                emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                ispined: pinned,
                emailtype: 1,
                name: data.name,
                email: data.email,
                color: color_class,
                attachment: data.attachment,
                emaildetails: data.details,
                emailTo: data.emailTo,
                snippet: data.details.snippet,
                subject: data.details.subject,
                body_html: data.details.body_html,
                comments: data.__commentlist__,
                email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
              }
            );
          }
          else if (data.email_type == 2) {
            logData.push(
              {
                logId: data.id,
                emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                ispined: pinned,
                emailtype: 2,
                name: data.name,
                email: data.email,
                color: color_class,
                attachment: data.attachment,
                emaildetails: data.details,
                emailTo: data.emailTo,
                snippet: data.details.snippet,
                subject: data.details.subject,
                body_html: data.details.body_html,
                comments: data.__commentlist__,
                email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
              }
            );
          }
          else if (data.email_type == 4) {
            logData.push(
              {
                logId: data.id,
                emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                ispined: pinned,
                emailtype: 4,
                name: data.name,
                email: data.email,
                color: color_class,
                attachment: data.attachment,
                emaildetails: data.details,
                emailTo: data.emailTo,
                snippet: data.details.snippet,
                subject: data.details.subject,
                body_html: data.details.body_html,
                comments: data.__commentlist__,
                email_date_time: moment.unix(data.createddate).tz(this.state.timeZone).format(this.state.dateformat) + ", " + moment.unix(data.createddate).tz(this.state.timeZone).format('hh:mm A'),
              }
            );
          }
        }
        else {
          let template = data.template_two;
          let color_class = (data.__user__.name) ? data.__user__.name.charAt(0) : "";
          let text = (data.text) ? data.text : "";
          let loguser = (data.__user__.email == logged_in_user_email) ? 'You' : data.__user__.name;
          let template1 = "";
          let new_template = "";

          if (template && template.indexOf("{{actor}}") != -1) {
            template1 = template.replace("{{actor}}", loguser);
          } else if (template && template.indexOf("{{user}}") != -1) {
            template1 = template.replace("{{user}}", loguser);
          } else {
            template1 = template;
          }


          // console.log(data.logtype, '---------logtype');

          let create_date = data.createddate;
          let updateddate = data.updateddate;

          if (data.logtype == 0) // Phone call
          {
            let template2 = this.setSourceDetail(data, template1);
            updateddate = data.logdate;
            new_template = template2.replace("{{property_name}}", '"' + data.propertyname + '"');
          }
          else if (data.logtype == 1) // create time
          {
            new_template = this.setSourceDetail(data, template1);
          }
          else if (data.logtype == 2) // name change
          {
            let old_template1 = this.setSourceDetail(data, template1);
            let old_template2 = old_template1.replace("{{property_name}}", '"' + data.propertyname + '"');

            let old_template3 = old_template2.replace("{{old_value}}", data.oldvalue);
            new_template = old_template3.replace("{{new_value}}", data.newvalue);
          }
          else if (data.logtype == 3 && data.__assignuser__) // owner change
          {
            let old_template = this.setSourceDetail(data, template1);

            let logAssignUser = (data.__assignuser__.email == logged_in_user_email) ? 'You' : data.__assignuser__.name;
            new_template = old_template.replace("{{target}}", '"' + logAssignUser + '"');
          }
          else if (data.logtype == 4) // for status
          {
            let old_template1 = this.setSourceDetail(data, template1);
            let old_template2 = old_template1.replace("{{property_name}}", '"' + data.propertyname + '"');

            let old_template = old_template2.replace("{{old_value}}", data.oldvalue);
            new_template = old_template.replace("{{new_value}}", data.newvalue);
          }
          else if (data.logtype == 5) // for note
          {
            let template2 = this.setSourceDetail(data, template1);
            updateddate = data.logdate;
            new_template = template2.replace("{{property_name}}", '"' + data.propertyname + '"');
          }
          else if (data.logtype == 6) // when assigned a contact
          {
            let template2 = this.setSourceDetail(data, template1);

            if (template2.indexOf("{{opportunities}}") != -1) {
              data.__opportunities__ && data.__opportunities__.__opportunitiesdetail__.map((oppolog) => {
                if (oppolog.__field__ && oppolog.__field__.id == "40") {
                  new_template = template2.replace("{{opportunities}}", '<a class="text_link" href="/opportunities/opportunities/' + oppolog.id + '" >' + oppolog.fieldvalue.trim() + '</a>');
                }
              });
            }
            if (template2.indexOf("{{people}}") != -1) {
              data.__people__ && data.__people__.__contactdetail__.map((poplelog) => {
                if (poplelog.__field__ && poplelog.__field__.id == "16") {
                  new_template = template2.replace("{{people}}", '<a class="text_link" href="/contact/contact/' + poplelog.id + '" >' + poplelog.fieldvalue.trim() + '</a>');
                }
              });
            }

          }
          else if (data.logtype == 7) // when assigned a company
          {
            let old_template = "";
            let template2 = this.setSourceDetail(data, template1);


            if (template2.indexOf("{{opportunities}}") != -1) {
              data.__opportunities__ && data.__opportunities__.__opportunitiesdetail__.map((oppolog) => {
                if (oppolog.__field__ && oppolog.__field__.id == "40") {
                  old_template = template2.replace("{{opportunities}}", '<a class="text_link" href="/opportunities/opportunities/' + oppolog.id + '" >' + oppolog.fieldvalue.trim() + '</a>');
                }
              });
            }

            if (template2.indexOf("{{people}}") != -1) {
              data.__people__ && data.__people__.__contactdetail__.map((companylog) => {
                if (companylog.__field__ && companylog.__field__.id == "16") {
                  old_template = template2.replace("{{people}}", '<a class="text_link" href="/contact/contact/' + companylog.id + '" >' + companylog.fieldvalue.trim() + '</a>');
                }
              });
            }


            if (data.__assignuser__) {
              let logAssignUser = (data.__assignuser__.email == logged_in_user_email) ? 'You' : data.__assignuser__.name;
              new_template = old_template.replace("{{target}}", '"' + logAssignUser + '"');
            }

          }
          else if (data.logtype == 8 || data.logtype == 9) // stage change opportunity
          {
            let template2 = this.setSourceDetail(data, template1);
            let old_template = this.setSourceDetail(data, template2);
            let old_template1 = old_template.replace("{{old_value}}", data.oldvalue);
            let old_template2 = old_template1.replace("{{property_name}}", '"' + data.propertyname + '"');
            new_template = old_template2.replace("{{new_value}}", data.newvalue);
          }
          else if (data.logtype == 15) // for contact type update in contact
          {
            let template2 = this.setSourceDetail(data, template1);
            let old_template = template2.replace("{{old_value}}", data.oldvalue);
            let old_template1 = old_template.replace("{{new_value}}", data.newvalue);
            new_template = old_template1.replace("{{property_name}}", '"' + data.propertyname + '"');
          }

          else if (data.logtype == 9) // status change of opportunity
          {
            new_template = template1.replace("{{source_type}}", "opportunity");
          }
          else if (data.logtype == 12) // for note
          {
            let old_template = template1.replace("{{property_name}}", data.propertyname);
            data.__opportunities__ && data.__opportunities__.__opportunitiesdetail__.map((opplog) => {
              if (opplog.__field__ && opplog.__field__.id == "16") {
                old_template3 = old_template2.replace("{{contact}}", opplog.fieldvalue);
              }
            });

            let old_template1 = old_template.replace("{{source_type}}", "contact");
            let old_template2 = old_template1.replace("{{actor}}", loguser);
            let old_template3 = "";
            data.__people__ && data.__people__.__contactdetail__.map((contactlog) => {
              if (contactlog.__field__ && contactlog.__field__.id == "16") {
                old_template3 = old_template2.replace("{{contact}}", contactlog.fieldvalue);
              }
            });
            let old_template4 = old_template3.replace("{{old_value}}", data.oldvalue);
            new_template = old_template4.replace("{{new_value}}", data.newvalue);
          }

          else if (data.logtype == 16) // contqact type change from contact
          {
            let template2 = this.setSourceDetail(data, template1);

            let old_template1 = template2.replace("{{property_name}}", data.propertyname);
            let old_template2 = old_template1.replace("{{actor}}", loguser);



            let old_template3 = "";
            data.__contact__ && data.__contact__.__contactdetail__.map((contactlog) => {
              if (contactlog.__field__ && contactlog.__field__.id == "16") {
                old_template3 = old_template2.replace("{{contact}}", contactlog.fieldvalue);
              }
            });
            let old_template4 = old_template3.replace("{{old_value}}", data.oldvalue);
            new_template = old_template4.replace("{{new_value}}", data.newvalue);
          }
          else if (data.logtype == 17) {
            //{{user}} added the Contact "{{contact}}"
            data.__contact__ && data.__contact__.__contactdetail__.map((contactlog) => {
              if (contactlog.__field__ && contactlog.__field__.id == "16") {
                new_template = template1.replace("{{contact}}", contactlog.fieldvalue);
              }
            });
          }
          else if (data.logtype == 18) {
            // "{{user}} assigned the Contact "{{contact}}" to {{target}}"
            let old_template = '';
            data.__contact__ && data.__contact__.__contactdetail__.map((companylog) => {
              if (companylog.__field__ && companylog.__field__.id == "16") {
                old_template = template1.replace("{{contact}}", companylog.fieldvalue);
              }
            });
            let logAssignUser = (data.__assignuser__.email == logged_in_user_email) ? 'You' : data.__assignuser__.name;
            new_template = old_template.replace("{{target}}", logAssignUser);
          }
          else if (data.logtype == 20) // for note
          {
            if (data.__task__ != null) {
              let old_template = template1.replace("{{task}}", data.__task__.__taskdetail__[0].fieldvalue);
              new_template = old_template.replace("{{source}}", source);
            }
          }
          else if (data.logtype == 21) // for note
          {
            if (data.__task__ != null) {
              let old_template = template1.replace("{{task}}", data.__task__.__taskdetail__[0].fieldvalue);
              let old_template1 = old_template.replace("{{source}}", source);
              new_template = old_template1.replace("{{target}}", loguser);
            }
          }

          if (new_template != "") {

            logData.push(
              {
                logId: data.id,
                emojidetail: data.emojidetail && data.emojidetail !== null ? JSON.parse(data.emojidetail) : [],
                mentionid: data.mentionid,
                mentiondetail: data.mentiondetail ? data.mentiondetail : "",
                ispined: pinned,
                emailtype: 0,
                comments: data.__commentlist__,
                activitytype: data.__activitytype__,
                user: data.__user__.name,
                useremail: data.__user__.email,
                propertyType: data.propertyname,
                color: color_class,
                template: new_template,
                text: text,
                logtype: data.logtype,
                time: (updateddate != null)
                  ?
                  moment.unix(updateddate).tz(this.state.timeZone).format('hh:mm A')
                  :
                  moment.unix(create_date).tz(this.state.timeZone).format('hh:mm A'),
                date: (updateddate != null)
                  ?
                  moment.unix(updateddate).tz(this.state.timeZone).format(this.state.dateformat)
                  :
                  moment.unix(create_date).tz(this.state.timeZone).format(this.state.dateformat)
              }
            );
          }
        }
      });
    }
    return { key: partKey, datas: logData };
  }

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  handleTypeChange = (event) => {
    this.setState({ value_type: event.target.value }, () => this.updateGraphSettings())
  }
  componentDidUpdate(PrevProps) {
    if (PrevProps.reportReducer.UserDashboardId !== this.props.reportReducer.UserDashboardId) {
      this.setState({
        memberid: this.props.reportReducer.UserDashboardId
      }, () => this.updateGraphSettings())
    }
  }
  updateGraphSettings = () => {
    let val = {
      limit: parseFloat(this.state.limit),
      pageno: parseFloat(this.state.currentPage),
      ismyactivity: (this.state.value_type == "true"),
      memberid: parseFloat(this.state.memberid)
    }
    this.props.getActivityReport(this.props.apolloClient.client, val);
  }
  render() {

    let { isOpenedEmailIds, isDataLoading, allLogs, isOpenedReplayEmailLog, isopenTrackdetails, logid, emaildata } = this.state;

    let loghtml = [];
    let logbody = [];
    let logheader = [];
    allLogs.map((logdata) => {
      logbody = [];
      logheader = [];
      logheader.push(
        <FilterLogoCommon headerKey={logdata.key} />
      )

      if (logdata.datas.length > 0) {
        logdata.datas.map((log) => {

          if (log.emailtype != 0) {
            if (log.emailtype == 1) {
              logbody.push(
                <EmailTypeOne
                  // allowUserReply={allowUserReply}
                  // commentvalue={commentvalue}
                  log={log}
                  logid={this.state.logid}
                  userName={this.state.userName}
                  lead_id={this.state.lead_id}
                  isOpenedEmailIds={this.state.isOpenedEmailIds}
                  showSelector={this.state.showSelector}
                  dateformat={this.state.dateformat}
                  timeZone={this.state.timeZone}
                  commentid={this.state.commentid}
                  isOpenedCommentIds={this.state.isOpenedCommentIds}
                  isEditComment={this.state.isEditComment}
                  thatAll={this}
                  transferLink={"/lead/lead/" + this.state.lead_id}
                  mainUserData={this.state.mainUserData}
                  isOpenedReplayEmailLog={isOpenedReplayEmailLog}
                  LogDetails={{
                    leadid: this.state.lead_id,
                    emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                    logtypeid: this.state.logtypeid,
                    activitypeid: this.state.activitypeid,
                  }}
                  emaildata={this.state.emaildata}
                  LogComponent="Lead"
                />
              )
            }
            else if (log.emailtype == 2) {
              logbody.push(
                <EmailTypeTwo
                  // allowUserReply={allowUserReply}
                  // commentvalue={commentvalue}
                  log={log}
                  logid={this.state.logid}
                  userName={this.state.userName}
                  lead_id={this.state.lead_id}
                  isOpenedEmailIds={this.state.isOpenedEmailIds}
                  showSelector={this.state.showSelector}
                  dateformat={this.state.dateformat}
                  timeZone={this.state.timeZone}
                  commentid={this.state.commentid}
                  isOpenedCommentIds={this.state.isOpenedCommentIds}
                  isEditComment={this.state.isEditComment}
                  thatAll={this}
                  transferLink={"/lead/lead/" + this.state.lead_id}
                  isOpenedReplayEmailLog={isOpenedReplayEmailLog}
                  mainUserData={this.state.mainUserData}
                  LogDetails={{
                    leadid: this.state.lead_id,
                    emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                    logtypeid: this.state.logtypeid,
                    activitypeid: this.state.activitypeid,
                  }}
                  emaildata={this.state.emaildata}
                  LogComponent="Lead"
                />
              )
            }
            else if (log.emailtype == 4) {
              logbody.push(
                <EmailTypeFour
                  // allowUserReply={allowUserReply}
                  // commentvalue={commentvalue}
                  log={log}
                  logid={this.state.logid}
                  userName={this.state.userName}
                  lead_id={this.state.lead_id}
                  isOpenedEmailIds={this.state.isOpenedEmailIds}
                  showSelector={this.state.showSelector}
                  dateformat={this.state.dateformat}
                  timeZone={this.state.timeZone}
                  commentid={this.state.commentid}
                  isOpenedCommentIds={this.state.isOpenedCommentIds}
                  isEditComment={this.state.isEditComment}
                  thatAll={this}
                  transferLink={"/lead/lead/" + this.state.lead_id}
                  isOpenedReplayEmailLog={isOpenedReplayEmailLog}
                  mainUserData={this.state.mainUserData}
                  LogDetails={{
                    leadid: this.state.lead_id,
                    emailid: localStorage.getItem('emailLogEmail') ? localStorage.getItem('emailLogEmail') : '',
                    logtypeid: this.state.logtypeid,
                    activitypeid: this.state.activitypeid,
                  }}
                  emaildata={this.state.emaildata}
                  LogComponent="Lead"
                />
              )
            }
          }
          else {
            logbody.push(
              <SystemLog log={log} />
            )
          }

        })

        if (logbody && logbody.length > 0) {
          loghtml.push(logheader);
          loghtml.push(logbody);
        }
      }
    })

    return (
      <Card elevation={3} className="mt-0 ml-0 mr-0 report_card" >

        <h3 className="text-primary p-20">{"Latest Activity"}</h3>

        <Grid container justify="space-between">
          <RadioGroup className="ml-16 mt-10" row aria-label="gender" name="value_type" value={this.state.value_type} onChange={this.handleTypeChange}>
            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="My Activity" />
            {/* <FormControlLabel value={"false"} control={<Radio color="primary" />} label="All Member`s Activity" /> */}
          </RadioGroup>
        </Grid>
        <div className="v_scroll position-relative" style={{ maxHeight: "273px" }}>
          <div className="sidebar_activity">
            {
              (loghtml.length == 0) ?
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ minHeight: '40vh' }}
                >
                  <Grid item xs={3}><h3 className="text-hint">No Activity found</h3></Grid>
                </Grid>
                :
                <>
                  {loghtml.map((day_log, t) => (
                    <div className="lead_upcoming" key={t}>
                      <div className="box_portlet">
                        {day_log}
                      </div>
                    </div>
                  ))
                  }
                  {
                    isDataLoading ?
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ minHeight: '10vh', backgroundColor: 'rgba(0,0,0,0)' }}
                      >
                        <Grid item xs={3}>
                          <CircularProgress disableShrink size={25} />
                        </Grid>
                      </Grid>
                      :
                      (this.state.hasMore) &&
                      // < Grid container item justify="center" className="m-20">
                      //   <Fab
                      //     variant="extended"
                      //     size="small"
                      //     color="primary"
                      //     onClick={this.fetchMoreData}
                      //     aria-label="add"
                      //     className="capitalize"
                      //   >
                      //     <Icon >expand_more</Icon>
                      //     Load More Activities</Fab>
                      // </Grid>
                      < Grid container item justify="center" className="m-20">
                        <span className="text-small text_link cursor-pointer"
                          onClick={this.fetchMoreData}
                        >
                          Load More Activities<Icon className="mb--8">expand_more</Icon>
                        </span>
                      </ Grid>
                  }
                </>
            }
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    settingReducer: state.settingReducer,
    reportReducer: state.reportReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getActivityReport: (client, value) => dispatch(actions.getActivityReport(client, value)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ActivityDashboard);