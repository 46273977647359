export default {
   // API: process.env.REACT_APP_API,

   API: "https://crmrestapi.kanhasoftdev.com/",  // staging
   //API: "https://restapi.idrivecrm.com/",  // client

   GraphApi: "https://crmapi.kanhasoftdev.com/graphql",
   //GraphApi:"https://api.idrivecrm.com/graphql", //Client new


   Socket_URI: `wss://crmapi.kanhasoftdev.com/graphql`, //staging URL
   //Socket_URI: `wss://api.idrivecrm.com/graphql`, //Client new

   Intregation_URI: "https://crmpython.kanhasoftdev.com/", // Dev
   //Intregation_URI : "https://crmpython.idrivecrm.com/", // Client

   BCCINBOX: "@inbox.kanhasoftdev.com",
   //BCCINBOX: "@inbox.idrivecrm.com"
}