import React, { Component } from "react";
import { Radio, CircularProgress, FormControlLabel, RadioGroup, Grid, Icon, Select, MenuItem, List, Fab, Button, DialogContent, ListItemText, ListItemAvatar, DialogTitle, Avatar, Card, ListItem } from "@material-ui/core";

import FontAwesome from "react-fontawesome";
import { Link } from "react-router-dom";

import moment from 'moment';
import 'moment-timezone';//timezone

import { connect } from "react-redux";
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
class MyOpenTasks extends Component {
  state = {
    tasklist: [],
    showMore: false,
    value_type: "true",
    limit: 5,
    totalPages: 0,
    currentPage: 1,
    isDataLoading: true,
    hasMore: false,
    pagesLoaded: [],
    memberid: 0,
    dateformat: 'MM/DD/YYYY',
    timeZone: 'America/New_York',
  };

  componentDidMount() {
    let val = {
      limit: parseFloat(this.state.limit),
      pageno: parseFloat(this.state.currentPage),
      ismytask: (this.state.value_type == "true"),
      memberid: 0
    }
    this.props.taskReportList(this.props.apolloClient.client, val);
  }

  componentWillReceiveProps(props) {
    if (props.settingReducer.userSettingArray && props.settingReducer.userSettingArray.data) {
      let usersetting = props.settingReducer.userSettingArray.data;

      let format = usersetting.dateformat;
      let timezone = usersetting.timezone;
      this.setState({ dateformat: format, timeZone: timezone })
    }

    if (props.reportReducer.opentaskreportdata && props.reportReducer.opentaskreportdata.data && props.reportReducer.opentaskreportdata.data) {
      let tasklist = props.reportReducer.opentaskreportdata.data.data.task;
      let totalpage = props.reportReducer.opentaskreportdata.data.data.totalpage;


      if (this.state.currentPage === 1) {
        this.setState({
          tasklist: tasklist,
          isDataLoading: false,
          hasMore: totalpage > this.state.currentPage ? true : false,
          totalPages: totalpage
        }, () => this.forceUpdate())
      } else {
        if (this.state.pagesLoaded.indexOf(this.state.currentPage) == -1) {
          this.AddNewData(tasklist)
        }
        this.setState({
          hasMore: totalpage > this.state.currentPage ? true : false,
          isDataLoading: false,
        }, () => this.forceUpdate())
      }

    }
    else {
      setTimeout(() => {
        this.setState({ isDataLoading: false, });
      }, 1500);
    }
    if (props.reportReducer.UserDashboardId) {
      if (this.state.memberid !== props.reportReducer.UserDashboardId) {
        {
          this.setState({
            memberid: props.reportReducer.UserDashboardId
          }, () => this.updateGraphSettings())
        }
      }
    }
  }
  componentDidUpdate(PrevProps) {
    if (PrevProps.reportReducer.UserDashboardId !== this.props.reportReducer.UserDashboardId) {
      this.setState({
        memberid: this.props.reportReducer.UserDashboardId
      }, () => this.updateGraphSettings())
    }
  }
  fetchMoreData = () => {
    // e.preventDefault();
    if (this.state.currentPage >= this.state.totalPages) {
      this.setState({ hasMore: false });
      return;
    }
    this.getNextPageData(this.state.currentPage + 1)
  };

  getNextPageData = (page) => {
    this.setState({
      currentPage: page, isDataLoading: true
    }, () => this.updateGraphSettings())
  }

  AddNewData = (taskdata) => {
    let pagesLoaded = this.state.pagesLoaded;
    var okData = [...this.state.tasklist]
    okData = [...this.state.tasklist, ...taskdata];
    pagesLoaded.push(this.state.currentPage);
    this.setState({ tasklist: okData, pagesLoaded })
  }

  handleTypeChange = (event) => {
    this.setState({
      value_type: event.target.value
    }, () => this.updateGraphSettings())
  }
  updateGraphSettings = () => {
    let val = {
      ismytask: (this.state.value_type == "true"),
      limit: parseFloat(this.state.limit),
      pageno: parseFloat(this.state.currentPage),
      memberid: parseFloat(this.state.memberid)
    }
    this.props.taskReportList(this.props.apolloClient.client, val);
  }
  render() {
    let { hasMore, isDataLoading, value_type, tasklist } = this.state
    return (
      <Card elevation={3} className="p-16 dashboard_card mb-10" >
        <Grid container justify="space-between">
          <h3 className="text-primary p-20">{"My Open Tasks"}</h3>
          <Link className="viewmore text_link" to="/task/task-list">View</Link>
        </Grid>
        <Grid container justify="space-between">
          <RadioGroup className="ml-16 mt-10" row aria-label="gender" name="value_type" value={value_type} onChange={this.handleTypeChange}>
            <FormControlLabel value={"true"} control={<Radio color="primary" />} label="My Tasks" />
            {/* <FormControlLabel value={"false"} control={<Radio color="primary" />} label="All Team Members’ Tasks" /> */}
          </RadioGroup>
        </Grid>

        <div className="v_scroll position-relative h-100">
          <List className="white_space_nowrap">
            {
              (tasklist.length > 0) ?
                <>
                  {
                    tasklist.map((fieldData, index) => (
                      <>

                        <div className="box_portlet" key={index}>
                          <div className="box_body">
                            <div className="lead_pl p-0">
                              <Grid container justify="space-between">
                                <Grid item md={1} className="pt-10">
                                  <Fab
                                    size="small"
                                    aria-label="Task"
                                    className={`wrap_icons_task vert-middle bg-success`}
                                  >
                                    <Icon className="hover_icon" >check_box_outline_blank</Icon>
                                    <FontAwesome className={"activity_icon fa-check-circle"} />
                                  </Fab>
                                </Grid>
                                <Grid item md={4}>
                                  <ListItemText
                                    primary={<div className="font-weight-500 text_ellipsis" style={{ width: '90%' }}>{fieldData.name && fieldData.name}</div>}
                                    secondary={<a className="text_link text_ellipsis" style={{ width: '350px' }}>{fieldData.owner && fieldData.owner[0].name}</a>}
                                  />
                                </Grid>
                                <Grid item md={7}>
                                  <ListItemText
                                    primary={<div className="font-weight-500">Due Date:  {fieldData.duedate && moment.unix(fieldData.duedate).tz(this.state.timeZone).format(this.state.dateformat)}</div>}
                                    secondary={<div className="font-weight-500">Associated with:  {fieldData.relatedto && fieldData.relatedto[0].value}</div>}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  }
                  {
                    isDataLoading ?
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ minHeight: '10vh', backgroundColor: 'rgba(0,0,0,0)' }}
                      >
                        <Grid item xs={3}>
                          <CircularProgress disableShrink size={25} />
                        </Grid>
                      </Grid>
                      :
                      (hasMore) &&
                      // < Grid container item justify="center" className="m-20">
                      //   <Fab
                      //     variant="extended"
                      //     size="small"
                      //     color="primary"
                      //     onClick={this.fetchMoreData}
                      //     aria-label="add"
                      //     className="capitalize"
                      //   >
                      //     <Icon >expand_more</Icon>
                      //         Load More Tasks</Fab>
                      // </Grid>
                      < Grid container item justify="center" className="m-20">
                        <span className="text-small text_link cursor-pointer"
                          onClick={this.fetchMoreData}
                        >
                          Load More Activities<Icon className="mb--8">expand_more</Icon>
                        </span>
                      </ Grid>
                  }
                </>
                :
                <div className="text-hint text-center font-weight-500 mt-50" >No Open Task found.</div>
            }
          </List>
        </div>
      </Card >
    );
  }
}

const mapStateToProps = state => {
  return {
    settingReducer: state.settingReducer,
    apolloClient: state.apolloClient,
    notification: state.notification,
    reportReducer: state.reportReducer,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    // getPipelines: (client) => dispatch(actions.getPipelines(client)),
    // getTagsList: (client) => dispatch(actions.getTagsList(client)),
    // getUserList: (client) => dispatch(actions.getUserList(client)),
    // getActivityTypes: (client) => dispatch(actions.getActivityTypes(client)),
    // customFieldList: (client, value) => dispatch(actions.customFieldList(client, value)),

    taskReportList: (client, value) => dispatch(actions.taskReportList(client, value)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyOpenTasks);
